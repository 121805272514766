const string = {
  completed: 'Selesai',
  ok: 'OKE',
  cancel: 'Batal',
  fail: 'Gagal',
  sent: 'Penarikan',
  sending: 'Sending a withdrawal',
  received: 'Deposit',
  receiving: 'Menerima setoran',
  value: 'Jumlah',
  myValue: 'Keseimbangan',
  fee: 'Biaya',
  more: 'Lebih lanjut',
  welcome: 'Selamat datang di Staking House!!!',
  localCoinUnit: ['KRW', 'USD', 'CNY', 'JPY'],
  error: 'Kesalahan',
  errorDetail: 'Terjadi kesalahan, silakan hubungi administrator',

  /*********signUp**********/
  signUp: 'Daftar',
  signUpMsg01: 'Permintaan pendaftaran Anda telah selesai',
  signUpMsg02: 'Email verifikasi telah dikirim ke ID (email) Anda yang terdaftar',
  signUpMsg03: 'Anda dapat masuk setelah menyelesaikan verifikasi email',
  signUpRuleMsg01: 'Alamat email ini sudah terdaftar',
  signUpRuleMsg02: 'Format email tidak valid',
  signUpRuleMsg03: 'Kompleksitas kata sandi tidak sesuai',
  signUpRuleMsg04: 'Terjadi kesalahan yang tidak diketahui saat memproses pendaftaran',
  signUpRuleMsg05: 'Dua kata sandi yang Anda masukkan berbeda',
  signUpRuleMsg06: 'Setujui syarat dan ketentuan',
  signUpRuleMsg07: 'Silakan masukkan 8-15 karakter dengan alfanumerik dan karakter khusus',
  signUpRuleMsg08: 'Masukkan nomor ponsel Anda dengan benar',
  signUpRuleMsg09: 'Silakan masukkan email Anda',
  signUpRuleMsg10: 'Silakan masukkan kata sandi',
  signUpRuleMsg11: 'Silakan masukkan konfirmasi kata sandi',
  signUpRuleMsg12: 'Silakan masukkan nama Anda',
  signUpRuleMsg13: "Silakan masukkan nomor telepon Anda",
  signUpRuleMsg14: "Silakan masukkan kata sandi pengaman 4 digit",
  signUpRuleMsg15: "Silakan masukkan kata sandi yang sama",

  signUpFailTitle: 'Pendaftaran gagal',
  signUpFail: 'Pendaftaran gagal',
  signUpCancelMsg: 'Apakah Anda ingin membatalkan keanggotaan Anda dan kembali ke halaman utama?',
  signUpAgreement: 'Pengumpulan dan penggunaan informasi pribadi',
  signUpOtherLoginMsg: 'Jika Anda menggunakan <span style="color:#00aab6; font-weight:bold;">Netflix</span> atau <span style="color:#00aab6; font-weight:bold;">Kakao</span>, Anda dapat <span style="color:#00aab6; font-weight:bold;">menggunakannya tanpa harus mendaftar</span>',
  emailRule03: 'Silakan masukkan format email Anda',
  cancle: 'Batalkan',
  idEmail: 'Id (email)',
  passwordRule: 'Kata sandi (8-15 karakter alfanumerik)',
  passwordConfirm: 'Konfirmasi kata sandi',
  name: 'Nama',
  inputTel: 'Masukkan nomor telepon Anda',
  inputSecurity: 'Kata sandi pengaman 4 digit',
  inputSecurityConfirm: 'Konfirmasi kata sandi pengaman',

  dupCheck: 'Pemeriksaan duplikat',
  emailDuplCheckError: 'Pemeriksaan duplikat email gagal',
  emailDuplCheckErrorEmailDupl: 'Email ini sudah terdaftar',
  emailDuplCheckErrorInvalidEmail: 'Format email tidak valid',
  needEmailDuplCheck: 'Pemeriksaan duplikat email diperlukan',

  sendCertiCode: 'Minta kode autentikasi',
  certiCodeFail: 'Silakan masukkan kode autentikasi Anda',
  certiCodeWrong: 'Anda telah memasukkan kode autentikasi yang salah',
  certiCodeSendFail: 'Gagal mengirim kode autentikasi',
  certiCodeNotVerified: 'Diperlukan verifikasi nomor telepon',

  signUpErrorTitle: 'Pembuatan dompet gagal',
  signUpErrorIdDuplikasi: 'Email ini sudah terdaftar, silakan periksa email Anda',
  signUpErrorInvalidEmailType: 'Jenis email tidak valid. Mohon periksa kembali',
  signUpErrorMaxPhone: 'Anda telah melebihi jumlah akun yang dapat didaftarkan per nomor telepon',
  signUpErrorMessage: 'Pembuatan dompet gagal. Silakan coba lagi nanti',

  emailResent: 'Email verifikasi telah dikirim ulang',
  emailResendFailed: 'Mengirim ulang email verifikasi gagal',
  invalidEmail: 'Email tidak valid',
  emailAlreadyVerified: 'Email ini telah diverifikasi',
  inputJoinEmail: 'Silakan masukkan email bergabung Anda',

  emailResendGuide1: 'Apakah Anda tidak menerima email verifikasi atau waktu verifikasi telah habis?',
  emailResendGuide2: 'Silakan klik tombol Kirim ulang email verifikasi di bawah ini untuk meminta verifikasi kembali',
  emailResend: 'Kirim ulang email verifikasi',
  
  findPasswordDescription: 'Masukkan email yang Anda gunakan untuk mendaftar dan link untuk mengubah kata sandi akan dikirimkan ke email Anda',
  findPasswordEmailSentSuccess: 'email telah dikirimkan kepada Anda untuk mengubah kata sandi',

  findId: 'Temukan ID',
  mnemonicError: 'Gagal menemukan ID',

  /*********Kutipan Harga**********/
  criteriaCoinmarketcap: '(Kriteria Coinmarket Cap)',
  updated:'Diperbarui',
  currency:'Harga pasar',
  notice:'Pemberitahuan',
  krw:'KRW',
  news:'Berita',
  note1:'Pemberitahuan',
  
  /*********Investasi*************/
  miningAccued:'Akumulasi penambangan',
  miningInvestment:'Investasi saya',
  miningGiveInfo:'Koin ZZ yang ditambang akan diberikan secara bertahap pada pukul 01:00 (GMT+9) keesokan harinya',
  miningToday: 'Status penambangan hari ini',
  miningDetail: 'Detail',
  miningDoInvestment: 'Lakukan investasi',
  coinNameZZ: 'ZZ',
  coinNameETH: 'ETH',


  /*********Transaksi**********/
  pilihKoin: 'Silakan pilih koin',
  sendCoinInputAmount: 'Silakan masukkan jumlah',
  sendCoinInputAmountLow: 'Jumlah minimum penarikan koin adalah 0.000001',
  sendCoinInputFee: 'Silakan masukkan biaya',
  sendCoinInputAddress: 'Silakan masukkan alamat Anda',
  sendCoinInputAddress2: 'Silakan masukkan alamat (alamat yang valid)',
  sendCoinLowBalance: 'Saldo Anda tidak cukup',
  sendCoinCurrentBalance: 'Saldo saat ini',
  sendCoinAvailableBalance: 'Saldo yang tersedia',
  // sendCoinLowBalanceXrp: '(Anda harus memiliki setidaknya 20 XRP)',
  sendCoinLowBalanceXrp: '',
  sendCoinLowFee: 'Nominal tidak cukup',
  sendCoinInputError: 'Gagal membuat informasi pengiriman uang',
  sendCoinSuccess: 'Transfer berhasil',
  sendCoinNeedConfirm: 'Mohon periksa catatan dan konfirmasi',
  sendCoinConfirmDlgTtl: 'Kirim',
  sendCoinConfirmMessage: 'Kirim koin seperti yang ditunjukkan di bawah ini. Setelah Anda mengirim koin, pengiriman tidak dapat dibatalkan. Apakah Anda yakin ingin mengirim?',
  sendCoinWarnMessage: 'Setelah diminta, pengiriman tidak dapat dibatalkan!',

  sendCoinCautionConfirmDescriptionEx: [
    'Mohon periksa kembali apakah jumlah dan alamat sudah benar',
    'Jumlah koin dipertahankan hingga 6 angka di belakang koma',
    'Jumlah di bawah 0. dapat mengalami kesalahan tergantung pada keadaan',
    'Karena sifat blockchain, Anda tidak dapat membatalkan setelah mengirim',
  ],

  sendCoinCautionConfirm: 'Konfirmasi pengiriman peringatan',
  sendCoinCautionConfirm2: 'Saya menyetujui tindakan pencegahan',
  sendCoinCautionFee: 'Biaya sekitar 0,01 ETH akan dikenakan untuk transfer blockchain',
  sendCoinCautionConfirmBtc: 'Harap diperhatikan bahwa Bitcoin (BTC), Bitcoin Cash (BCH), dan Bitcoin Gold (BCG) adalah mata uang kripto yang berbeda, jadi harap berhati-hati saat melakukan penyetoran dan penarikan',
  sendCoinCautionConfirmEth: 'Harap berhati-hati saat menyetor dan menarik Ethereum (ETH) dan Ethereum Classic (ETC) karena keduanya adalah mata uang kripto yang berbeda',
  sendCoinCautionConfirmXrp: 'Ripple (XRP) mengharuskan Anda untuk memiliki setidaknya 20 XRP di dompet Anda, jadi ketika Anda menarik, Anda hanya dapat menarik 20 XRP dari saldo Anda',
  sendCoinCautionConfirmErc20: 'Biaya ether yang digunakan untuk mentransfer token Ethereum mungkin lebih atau kurang dari nilai yang Anda tetapkan tergantung pada kondisi jaringan. Oleh karena itu, Anda harus memiliki 3x biaya yang diinginkan di dompet Anda pada saat penarikan',
  sendCoinCautionConfirmDescription: [
    'Kami mempertahankan jumlah koin hingga 6 angka di belakang koma, dan jumlah di bawah 0,0001 dapat mengalami kesalahan tergantung pada situasinya',
    'Mungkin diperlukan waktu lebih dari 1 jam untuk menyelesaikan transfer setelah pengiriman, dan status transfer dapat diperiksa di riwayat transaksi',
    'Jika Anda menyetor atau menarik ke atau dari dompet bursa lain, Anda mungkin akan terpengaruh oleh kebijakan bursa tersebut',
    'Karena sifat mata uang kripto, Anda tidak dapat membatalkan pengiriman setelah pengiriman',
    'Harap periksa alamat sebelum mengirim',
    'Anda hanya dapat mengirim ke alamat dengan koin yang sama dengan koin ini. Jika Anda salah mengirim ke alamat koin yang berbeda, koin tidak akan ditemukan',
    'Masalah setoran dan penarikan yang tertunda karena kelebihan blockchain tidak dapat ditangani oleh stakingHouse',
  ],
  sendCoinXrpUseTag: 'Gunakan Tag',
  sendCoinFailedTitle: 'Transfer koin gagal',
  sendCoinFailed: 'Transfer koin gagal. Silakan coba lagi nanti',
  sendCoinFailedInvalidAddress: 'Alamat yang Anda kirimkan tidak valid',
  sendCoinFailedInsufficientBalance: 'Saldo Anda tidak mencukupi',
  sendCoinFailedLocked: 'Koin terkunci',
  sendCoinFailedInvalidOtp: 'Informasi OTP tidak valid',
  sendCoinFailedInvalidSecPassword: 'Kata sandi keamanan tidak valid',
  sendCoinFailedNoSecPassword: 'Masukkan kata sandi aman 4 digit',
  sendCoinFailedInvalidDesTag: 'Tag Tujuan tidak ada',
  sendCoinFailedOtpRequired: 'Anda telah melampaui batas harian untuk mengirim tanpa OTP',
  sendCoinFailedLimitExceed: 'Jumlah batas harian telah terlampaui',
  sendCoinFailedSecurityLevelRequired: 'OTP dan KYC harus dilengkapi untuk mengirim uang',
  sendCoinFailedKycDay: 'Koin tidak dapat dikirim selama satu hari setelah KYC selesai',
  sendCoinSuccessTitle: 'Permintaan transfer koin berhasil',
  includeAmount: 'Jumlah yang dicerminkan',
  includedAmount: 'Jumlah setoran yang disertakan dalam QR',
  includeAmountCaution: 'Kode QR dengan jumlah tersebut tidak kompatibel dengan dompet lain',
  inputDestinationTag: 'Masukkan tag tujuan (opsional)',
  inputOtpValidation: 'Masukkan OTP Anda',
  feeRequestFailed: 'Permintaan biaya gagal',
  exemption: 'Pembebasan (transfer antar anggota)',

  /*********Isi Transaksi**********/
   noCoins: 'Tidak ada informasi perdagangan',
  tradeLog: 'Log perdagangan',
  walletSend: 'Kirim',
  walletReceive: 'Terima',
  selectCoin: 'Pilih koin',
  copyComplete: 'Salinan selesai',
  address: 'Alamat dompet',
  priceInsert: 'Masukkan harga',
  copyAddress: 'Alamat salinan',
  qrContainPrice: 'Harga setoran yang terkandung dalam QR',
  depositAddress: 'Alamat setoran',
  insertAddress: 'Masukkan alamat',
  insertAmount: "Masukkan jumlah",
  insertMemo: "Memo (opsional)",
  securityPw: "Kata sandi keamanan",
  pleaseInsertAddress: "Silakan masukkan alamat Anda",
  pleaseInsertAmount: "Silakan masukkan jumlah.",
  pleaseInsertSecurityPw: "Silakan masukkan kata sandi keamanan Anda",
  pleaseInsertOtp: "Silakan masukkan otp.",
  externalTrade: "Eksternal",
  adjustAccount: "Penyesuaian",
  recieveUsdtCaution: 'Ini adalah USDT berbasis ERC20. Harap diperhatikan bahwa jika Anda menyetor USDT dari chain lain, kami tidak akan dapat menemukannya',


  /*********Terms, etc.**********/
  policyView: 'Lihat Ketentuan',
  needAgreement: 'Mohon setujui persyaratan',
  policyTitle: 'Pengumpulan Informasi Pribadi dan Ketentuan Penggunaan',
  policyTitle2: '(Wajib) Pengumpulan Informasi Pribadi dan Ketentuan Penggunaan',
  policyContent: [
    { title: 'Bab 1 Aturan Umum', content: '' },
    {
      title: 'Pasal 1 (Tujuan)',
      content:
        'Syarat dan Ketentuan ini dimaksudkan untuk menetapkan hak, kewajiban dan tanggung jawab serta hal-hal lain yang diperlukan antara perusahaan dan pengguna mengenai syarat, ketentuan, dan prosedur layanan (selanjutnya disebut layanan) yang disediakan oleh aplikasi dan dompet yang disediakan oleh stakingHouse.\n',
    },
    {
      title: 'Pasal 2 (Klarifikasi, Penjelasan dan Perubahan Istilah)',
      content: [
        
        
        '③ Jika Perusahaan merevisi Ketentuan, Perusahaan akan menentukan tanggal penerapan dan alasan revisi serta memberitahukan Ketentuan saat ini di layar awal atau layar pop-up situs Perusahaan atau sebagai pengumuman 7 hari sebelum tanggal penerapan. Namun, jika terjadi perubahan yang merugikan atau signifikan bagi pengguna, pengguna akan diberitahu 30 hari sebelum tanggal efektif.\n ',
        '④ Jika Perusahaan memberi tahu Pengguna tentang Ketentuan yang direvisi sesuai dengan paragraf sebelumnya dan dengan jelas memberi tahu Pengguna bahwa jika Pengguna tidak menyatakan keinginannya dalam jangka waktu 7 hari, Pengguna dianggap telah menyatakan keinginannya, tetapi Pengguna tidak menyatakan penolakannya, Pengguna dianggap telah menyetujui Ketentuan yang direvisi',
        '⑤ Jika pengguna menyatakan secara tegas untuk tidak menyetujui penerapan persyaratan yang direvisi, perusahaan tidak dapat menerapkan isi persyaratan yang direvisi, dalam hal ini pengguna dapat mengakhiri kontrak penggunaan. Namun, jika ada keadaan khusus yang mencegah penerapan syarat dan ketentuan yang ada, perusahaan dapat mengakhiri kontrak penggunaan.\n ',
      ]
    },
    {
      title: 'Pasal 3 (Aturan selain Syarat dan Ketentuan)',
      content:
        'Perusahaan dapat membuat pemberitahuan terpisah, syarat dan ketentuan individu, perjanjian individu, panduan pengguna, kebijakan operasi, instruksi terperinci, dll. (selanjutnya secara kolektif disebut sebagai "instruksi terperinci") untuk Layanan sebagai tambahan dari Syarat dan Ketentuan ini untuk memberi tahu pengguna atau mendapatkan persetujuan mereka, dan jika isi dari instruksi terperinci dengan persetujuan pengguna bertentangan dengan Syarat dan Ketentuan ini, maka instruksi terperinci akan diutamakan. Segala hal atau interpretasi yang tidak diatur dalam Ketentuan ini akan diatur oleh Petunjuk Detail dan hukum dan peraturan yang relevan atau praktik umum.\n',
    },
    {
      title: 'Pasal 4 (Definisi Istilah)',
      content: [
        '① Definisi istilah yang digunakan dalam Syarat dan Ketentuan ini adalah sebagai berikut:\n',
        '1.Cryptocurrency: mengacu pada mata uang yang ada secara elektronik di blockchain, seperti Bitcoin (BTC), Ethereum (ETH), dll.',
        '2. Pengguna: mengacu pada pengguna yang telah menerima Ketentuan ini dan mendaftarkan dompet yang disediakan oleh Perusahaan',
        '3. Aplikasi: mengacu pada program khusus online ponsel cerdas yang memungkinkan Anda untuk menyimpan, mengirim, dan menerima mata uang kripto',
        '4. Dompet: Aplikasi yang memungkinkan Anda menyimpan mata uang kripto di ponsel cerdas Anda',
        '5. Kata sandi: Kombinasi karakter yang ditetapkan oleh pengguna untuk memverifikasi identitas pengguna dan dompet serta untuk mengamankan mata uang kripto',
        '6.Kata pemulihan: 12 kata terenkripsi yang dapat dipulihkan jika dompet hilang',
      ]
    },
    {
      title: 'Bab 2 Permohonan dan Penerimaan Penggunaan Layanan (Pendaftaran dan Penghentian Pengguna)',
      content: '',
    },
    {
      title: 'Pasal 5 (Pembentukan Perjanjian Penggunaan)',
      content: [
        '① Pengguna mengajukan permohonan untuk menggunakan dompet dengan menunjukkan bahwa ia menyetujui syarat dan ketentuan ini saat membuat dompet di aplikasi yang disediakan oleh perusahaan',
        '② Kontrak penggunaan diakhiri dengan mendaftarkan dompet. Ketika kontrak penggunaan dibuat, pemohon terdaftar sebagai pengguna dompet.\n'
      ]
    },
    {
      title: 'Pasal 6 (Permohonan Penggunaan)',
      content: 'Permohonan penggunaan secara otomatis disetujui ketika dompet terdaftar di aplikasi yang disediakan oleh perusahaan.\n',
    },
    {
      title: 'Pasal 7 (Persetujuan Penggunaan Informasi Pengguna dan Persetujuan Aplikasi)',
      content: [
        '① Persetujuan penggunaan informasi pengguna\n',
        '1.Perusahaan akan menggunakan informasi pribadi pengguna untuk tujuan memenuhi Perjanjian ini dan menyediakan layanan berdasarkan Perjanjian ini\n',
        '2.Agar pengguna dapat menggunakan Perusahaan dan layanan afiliasinya dengan mudah, informasi pengguna dapat diberikan kepada perusahaan yang berafiliasi dengan Perusahaan. Namun, sebelum memberikan informasi pengguna, Perusahaan harus memberi tahu perusahaan afiliasi, tujuan pemberian, dan isi informasi pengguna yang akan diberikan terlebih dahulu dan mendapatkan persetujuan pengguna',
        '3.Pengguna dapat melihat dan mengubah informasi pribadi mereka kapan saja dengan mengubah informasi pengguna mereka',
        '4.Dengan mengisi informasi pengguna dalam formulir aplikasi pengguna dan mengajukan permohonan penggunaan Perusahaan sesuai dengan Ketentuan ini, pengguna menyetujui pengumpulan, penggunaan, dan penyediaan informasi pengguna yang dijelaskan dalam formulir aplikasi pengguna sesuai dengan Ketentuan ini',
      ]
    },
    {
      title: 'Pasal 8 (Penangguhan dan Pengakhiran Perjanjian Penggunaan)',
      content: [
        '① Pengakhiran Perjanjian Penggunaan dan penggunaan Aplikasi dapat dibatalkan oleh pengguna dengan menghapus Aplikasi dari ponsel cerdas',
        '② Perusahaan mungkin tidak menyetujui permohonan pemohon untuk berlangganan jika terdapat salah satu dari alasan berikut ini, dan dapat membatalkan persetujuan atau mengakhiri kontrak penggunaan jika alasan di setiap item berikut dikonfirmasi bahkan setelah berlangganan.\n',
        '1. Jika Anda sebelumnya telah kehilangan keanggotaan Anda berdasarkan Syarat dan Ketentuan ini\n',
        '2. Jika pemohon menyatakan atau memberikan informasi yang salah atau tidak benar, atau tidak menyatakan konten yang disajikan oleh perusahaan\n',
        '3. Jika aplikasi tidak dapat disetujui karena alasan yang disebabkan oleh pemohon, atau jika aplikasi dibuat dengan melanggar ketentuan lain dari Syarat dan Ketentuan ini',
        '4. Jika Anda berniat untuk menggunakan layanan untuk tujuan penipuan atau tujuan bisnis yang terpisah',
        '5. Jika Anda mengajukan permohonan untuk tujuan yang melanggar hukum yang relevan atau dapat mengganggu kesejahteraan masyarakat atau ketertiban umum dan moral',
        '6. Jika usia pemohon kurang dari 19 tahun\n',
        '③ Perusahaan dapat meminta verifikasi nama asli atau verifikasi identitas melalui organisasi profesional sejauh diizinkan oleh hukum untuk memverifikasi apakah informasi yang diberikan oleh anggota sesuai dengan fakta yang ada',
      ]
    },
    { title: 'Bab 3 Kewajiban Perusahaan', isi: '' },
    {
      title: 'Pasal 9 Kewajiban Perusahaan terhadap Informasi dan Layanan Pribadi Pengguna',
      content: [
        '(1) Perusahaan harus mematuhi hukum dan peraturan yang relevan serta Syarat dan Ketentuan ini, dan akan melakukan yang terbaik untuk menyediakan Layanan secara terus menerus dan stabil',
        
        '③ Sehubungan dengan bisnisnya, Perusahaan dapat, tanpa persetujuan sebelumnya dari pengguna, mengumpulkan dan menggunakan informasi pribadi dari semua atau sebagian pengguna ke dalam data statistik yang tidak dapat membedakan informasi pengguna tertentu, dan untuk tujuan ini, pengguna dapat mengirim cookie ke komputer. Namun, dalam hal ini, pengguna dapat mengubah pengaturan perangkat yang digunakan untuk menolak pengiriman cookie atau untuk memperingatkan pengguna tentang pengiriman cookie, tetapi dalam kasus ini, penggunaan layanan dapat berubah tanpa alasan apa pun yang dapat diatribusikan kepada perusahaan karena perubahan pengaturan cookie',
        '④ Perusahaan akan menangani pendapat atau keluhan yang disampaikan oleh pengguna sehubungan dengan penggunaan layanan jika pendapat atau keluhan tersebut dibenarkan, dan dapat mengkomunikasikan proses dan hasilnya kepada pengguna melalui papan buletin dalam layanan, email, dll.\n',
        '⑤ Perusahaan harus mematuhi hukum dan peraturan yang terkait dengan operasi dan pemeliharaan Layanan, seperti Undang-Undang Jaringan Informasi dan Komunikasi, Undang-Undang Perlindungan Informasi Lokasi, Undang-Undang Perlindungan Rahasia Komunikasi, dan Undang-Undang Bisnis Telekomunikasi',
      ]
    },
    { title: 'Bab 4 Kewajiban Pengguna', isi: '' },
    {
      title: 'Pasal 10 (Kewajiban pengguna untuk mengelola dompet pengguna dan kata sandi)',
      content: [
        '① Pengguna bertanggung jawab atas semua pengelolaan dompet dan kata sandi mereka. Pengguna bertanggung jawab atas semua konsekuensi yang timbul akibat kelalaian pengelolaan atau penggunaan yang tidak sah atas dompet dan kata sandi yang didaftarkan oleh pengguna, dan Perusahaan tidak bertanggung jawab atas konsekuensi tersebut. Namun, hal ini tidak berlaku untuk kasus-kasus yang disebabkan oleh kesengajaan Perusahaan',
        '② 12 kata pemulihan harus ditulis di selembar kertas, dan 12 kata pemulihan diperlukan ketika dompet hilang dan ditemukan kembali. Perusahaan tidak memiliki informasi ini, jadi jika Anda kehilangan kata pemulihan, Anda tidak akan dapat memulihkannya, dan perusahaan tidak akan bertanggung jawab atas hal ini. Selain itu, jika Anda kehilangan 12 kata pemulihan karena kesalahan Anda sendiri, Anda akan bertanggung jawab untuk ini, dan perusahaan tidak akan bertanggung jawab untuk ini.\n ',
      ]
    },
    {
      title: 'Pasal 11 (Penyediaan Informasi)',
      content: [
        '1. Perusahaan dapat memberikan informasi tentang layanan berikut ini untuk kenyamanan penggunaan dan informasi tentang produk melalui metode seperti telepon kabel dan nirkabel, email, layanan teks (LMS/SMS), dan SNS. Dalam hal ini, pengguna dapat menolak untuk menerimanya kapan saja.\n ',
        '① Layanan yang terkait dengan acara dan kegiatan\n',
        '② Layanan lain yang ditentukan oleh Perusahaan dari waktu ke waktu dan diberikan kepada pengguna',
        '2. Pengguna dapat melihat informasi pribadi mereka kapan saja melalui Layanan dan meminta modifikasi atau koreksi. Jika terjadi perubahan dalam informasi yang diberikan saat mendaftar keanggotaan, pengguna harus secara langsung mengubah informasi dalam layanan atau memberi tahu perusahaan tentang perubahan tersebut melalui pusat pelanggan. Perusahaan tidak akan bertanggung jawab atas kerugian yang disebabkan oleh kegagalan pengguna untuk memberi tahu perusahaan tentang perubahan pada paragraf sebelumnya',
      ]
    },
    { title: 'Bab 5 Aturan Umum Penggunaan Layanan', konten: '' },
    {
      title: 'Pasal 12 (Jenis Layanan)',
      content: [
        '\nLayanan yang disediakan oleh Perusahaan mencakup fungsi dompet untuk menyimpan mata uang kripto dan fungsi untuk mengirim dan menerima mata uang kripto kepada orang lain. Namun, pengguna bertanggung jawab atas keakuratan alamat dompet mata uang kripto yang diberikan oleh pengguna, dan Perusahaan tidak bertanggung jawab atas pengiriman mata uang kripto ke alamat dompet mata uang kripto yang tidak disebutkan dengan benar oleh pengguna',
        '② Jenis layanan yang disediakan oleh Perusahaan dapat berubah dari waktu ke waktu karena keadaan Perusahaan, dan hak cipta dan hak kekayaan intelektual untuk layanan yang disediakan adalah milik Perusahaan',
        '③ Perusahaan hanya memberikan hak kepada pengguna untuk menggunakan akun, dompet, layanan, dll. sesuai dengan syarat dan ketentuan yang ditetapkan oleh Perusahaan sehubungan dengan layanan tersebut, dan pengguna tidak diizinkan untuk menyediakan layanan serupa dan aktivitas komersial dengan menggunakannya.\n ',
      ]
    },
    {
      title: 'Pasal 13 (Pemberitahuan dan Perubahan Konten Layanan)',
      content: [
        
        '② Perusahaan dapat mengubah semua atau sebagian dari layanan individual yang disediakan untuk kebutuhan operasional dan teknis jika ada alasan yang kuat. Dalam hal ini, perusahaan akan memberi tahu pengguna setidaknya 7 hari sebelumnya',
      ]
    },
    {
      title: 'Pasal 14 (Pemeliharaan dan Penangguhan Layanan)',
      content: [
         'Namun, hal ini tidak berlaku untuk hari atau waktu yang ditetapkan oleh Perusahaan sebelumnya atau sesudahnya karena kebutuhan untuk pemeliharaan rutin',
        '② Perusahaan dapat membagi layanan ke dalam cakupan tertentu dan menetapkan waktu yang tersedia secara terpisah untuk setiap cakupan. Dalam hal ini, konten akan diberitahukan sebelumnya atau sesudahnya.\n',
        '③ Perusahaan dapat berhenti menyediakan Layanan dalam salah satu kasus berikut. Dalam hal ini, Perusahaan akan memberitahukan penghentian penyediaan layanan terlebih dahulu kecuali jika hal tersebut tidak dapat dihindari.\n ',
        '1. Kasus yang tidak dapat dihindari karena konstruksi seperti perbaikan fasilitas layanan\n',
        '2. Jika layanan telekomunikasi ditangguhkan oleh penyedia layanan telekomunikasi yang diatur dalam Undang-Undang Bisnis Telekomunikasi\n',
        '3. Dalam hal layanan yang disediakan dengan menggunakan pihak ketiga seperti afiliasi dan bukan secara langsung oleh Perusahaan, jika pihak ketiga seperti afiliasi menghentikan layanan\n',
        '4. Jika ada alasan keadaan kahar lainnya\n',
        '④ Perusahaan dapat membatasi atau menangguhkan semua atau sebagian Layanan ketika ada hambatan terhadap penggunaan normal Layanan, seperti keadaan darurat nasional, pemadaman listrik, kegagalan fasilitas layanan, atau penggunaan Layanan yang berlebihan',
      ]
    },
    {
      title: 'Pasal 15 Pembatasan Penggunaan',
      content: [
        '① Perusahaan dapat membatasi penggunaan dan akses anggota ke layanan dalam salah satu kasus berikut:\n',
        '1. Jika terjadi kesalahan kata sandi dan kata sandi keamanan secara terus menerus\n',
        '2. Jika terjadi peretasan atau kecelakaan',
        '3. Dalam kasus dugaan pencurian identitas\n',
        '4. Ketika organisasi nasional meminta pembatasan layanan sesuai dengan hukum dan peraturan yang relevan',
        '5. Jika Anda terlibat atau dicurigai terlibat dalam pencucian uang, perdagangan tidak adil, kegiatan kriminal, dll',
        '6. Jika pengguna yang terdaftar sebagai anggota dikonfirmasi berusia di bawah 19 tahun',
        '7. Jika salah satu alasan lain di bawah masing-masing paragraf sebelumnya terjadi atau jika tindakan diperlukan untuk mencegah terjadinya alasan tersebut\n',
        '② Perusahaan dapat membatasi penggunaan deposit dan penarikan anggota jika salah satu dari yang berikut ini berlaku:\n ',
        '1. Jika pengguna yang terdaftar sebagai anggota dikonfirmasi berusia di bawah 19 tahun\n',
        '2. Insiden peretasan dan penipuan telah terjadi atau dicurigai telah terjadi\n',
        '3. Pencurian identitas dicurigai terjadi\n',
        '4. Otoritas nasional meminta pembatasan layanan sesuai dengan hukum dan peraturan yang relevan\n',
        '5. jika kami mencurigai Anda terlibat atau telah terlibat dalam pencucian uang, perdagangan yang tidak adil, aktivitas kriminal, dll',
        '6. Atas permintaan anggota itu sendiri\n',
        '7. Jika mata uang kripto ditransfer tetapi tidak tercermin dalam akun anggota, atau jika setoran atau penarikan dibuat berbeda dari permintaan setoran atau penarikan yang sebenarnya\n',
        '8. Jika alasan yang sesuai dengan salah satu alasan lain yang tercantum dalam paragraf sebelumnya terjadi, atau jika tindakan diperlukan untuk mencegah terjadinya alasan tersebut\n',
        '③ Jika Perusahaan membatasi penggunaan layanan atau mengakhiri kontrak penggunaan sesuai dengan Pasal ini, Perusahaan akan memberi tahu anggota melalui email, SMS, App Push, dll.',
        '④ Anggota dapat mengajukan banding atas pembatasan penggunaan berdasarkan Pasal ini sesuai dengan prosedur yang ditetapkan oleh perusahaan. Pada saat ini, jika perusahaan mengakui bahwa keberatan tersebut dapat dibenarkan, perusahaan akan segera melanjutkan penggunaan layanan.\n ',
      ]
    },
    {
      title: 'Pasal 16 (Pengakhiran Perjanjian Penggunaan)',
      content: [
        '① Pengguna dapat mengajukan permohonan penghentian kontrak penggunaan melalui pusat pelanggan kapan saja, dan perusahaan akan segera memprosesnya sesuai dengan hukum dan peraturan yang relevan',
        '② Jika alasan berikut ini terjadi pada anggota, perusahaan dapat membatasi penggunaan layanan untuk jangka waktu tertentu dan membuat permintaan perbaikan. Jika, meskipun telah diminta untuk diperbaiki, hal tersebut tidak diperbaiki dalam jangka waktu yang wajar, atau jika anggota berulang kali melakukan pelanggaran yang sama, kontrak penggunaan dapat dihentikan.\n ',
        '1. Jika Anda melanggar kewajiban Anda sebagaimana diatur dalam Pasal 10 Ketentuan ini atau termasuk dalam alasan pembatasan penggunaan sebagaimana diatur dalam Pasal 15\n', '2. Jika Anda melanggar kewajiban Anda sebagaimana diatur dalam Pasal 10 Ketentuan ini atau termasuk dalam alasan pembatasan penggunaan sebagaimana diatur dalam Pasal 15\n',
        '2. Jika terjadi pelanggaran terhadap hukum dan peraturan yang relevan, seperti penyediaan program ilegal yang melanggar hukum hak cipta, gangguan terhadap operasi, komunikasi ilegal dan peretasan yang melanggar Undang-Undang tentang Promosi Pemanfaatan Jaringan Informasi dan Komunikasi dan Perlindungan Informasi, distribusi program berbahaya, hak akses yang berlebihan, dll',
        '3. Jika Anda telah melakukan atau mencoba melakukan tindakan yang mengganggu kelancaran layanan yang disediakan oleh Perusahaan',
        '4. Jika ada alasan lain di bawah setiap paragraf sebelumnya yang membuat Perjanjian ini tidak mungkin untuk dipertahankan',
        '③ Setelah pengakhiran kontrak penggunaan sesuai dengan paragraf sebelumnya, semua manfaat yang diperoleh melalui penggunaan layanan akan hilang, dan perusahaan tidak akan memberikan kompensasi secara terpisah untuk hal ini',
        '④ Jika terjadi pemutusan kontrak penggunaan layanan sesuai dengan Pasal ini, Perusahaan akan memberi tahu pengguna melalui email, SMS, App Push, dll',
        '⑤ Setelah kontrak penggunaan layanan berakhir, semua informasi pengguna akan dihapus kecuali informasi yang harus disimpan oleh perusahaan sesuai dengan hukum dan peraturan yang berlaku dan kebijakan privasi',
        '⑥ Jika Perusahaan mengakhiri Perjanjian Penggunaan sesuai dengan Paragraf 2, Perusahaan dapat menyimpan informasi Pengguna untuk jangka waktu tertentu untuk menerima dan memproses banding Pengguna, dll. dan akan menghapus informasi Pengguna (tetapi bukan riwayat setoran dan penarikan) setelah jangka waktu tersebut berlalu',
      ]
    },
    {
      title: 'Pasal 17 (Penyediaan Informasi dan Penempatan Iklan)',
      content: [
        '① Dalam mengoperasikan Layanan, Perusahaan dapat menampilkan berbagai informasi yang terkait dengan Layanan pada layar Layanan atau memberikannya kepada pengguna melalui email, SMS, App Push, dll.',
        '② Dalam mengoperasikan Layanan, Perusahaan dapat menampilkan berbagai iklan Perusahaan atau afiliasinya di layar Layanan atau menyediakannya kepada pengguna melalui E-mail, SMS, App Push, dll. dengan persetujuan pengguna',
      ]
    },
    { title: 'Bab 5 Privasi', konten: '' },
    {
      title: 'Pasal 18 (Perlindungan Informasi Pribadi)',
      content: [
        'Perusahaan berusaha untuk melindungi informasi pribadi Anda sesuai dengan hukum dan peraturan yang relevan. Informasi pribadi Anda hanya akan digunakan untuk tujuan dan dalam ruang lingkup yang telah disetujui oleh Anda demi kelancaran penyediaan Layanan. Perusahaan tidak akan memberikan informasi pribadi pengguna kepada pihak ketiga kecuali diwajibkan oleh hukum atau disetujui oleh pengguna, dan perinciannya diatur dalam Kebijakan Privasi',
      ]
    },
    { title: 'Bab 6 Kerusakan dan Sanggahan', konten: '' },
    {
      title: 'Pasal 19 (Ganti Rugi dan Kerugian Perusahaan)',
      content: [
        '① Perusahaan tidak menjamin hal-hal yang tidak disebutkan dalam Syarat dan Ketentuan ini sehubungan dengan Layanan. Selain itu, Perusahaan tidak menjamin nilai mata uang kripto yang tidak diterbitkan atau dijamin akan dibayarkan oleh Perusahaan',
        '② Perusahaan akan dibebaskan dari tanggung jawab untuk menyediakan Layanan jika Perusahaan tidak dapat menyediakan Layanan karena bencana alam, serangan DDos, kegagalan IDC, server down karena lonjakan akses layanan, atau alasan force majeure lainnya, seperti kegagalan saluran penyedia telekomunikasi jangka panjang. Namun, hal ini tidak berlaku jika terjadi kelalaian yang disengaja atau berat dari Perusahaan',
        '③ Perusahaan tidak bertanggung jawab atas kegagalan yang tidak dapat dihindari karena masalah blockchain, cacat atau masalah teknis dari sistem manajemen penerbitan mata uang kripto itu sendiri, cacat penyedia layanan telekomunikasi, pemeliharaan server reguler, dll. Namun, hal ini tidak berlaku untuk kasus-kasus yang disengaja atau kelalaian besar dari Perusahaan',
        '④ Perusahaan tidak bertanggung jawab atas gangguan atau konsekuensi penggunaan layanan yang disebabkan oleh alasan yang disebabkan oleh pengguna, namun hal ini tidak berlaku jika pengguna memiliki alasan yang sah.\n',
        '⑤ Perusahaan akan dibebaskan dari tanggung jawab jika terjadi transaksi antara pengguna atau antara pengguna dan pihak ketiga melalui Layanan. Namun, hal ini tidak berlaku jika terjadi kelalaian yang disengaja atau kelalaian besar dari Perusahaan.\n ',
        '⑥ Perusahaan tidak bertanggung jawab atas penggunaan layanan yang disediakan secara gratis kecuali ada ketentuan khusus dalam hukum yang relevan.\n',
        '⑦ Perusahaan dapat melakukan pemeriksaan server secara rutin, tidak rutin, atau mendesak untuk menyediakan layanan yang stabil.\n',
        '⑧ Jika ada orang yang memperoleh atau menerima mata uang kripto milik pihak ketiga tanpa otorisasi karena kesalahan layanan, kegagalan komputer, atau alasan lain, Perusahaan dapat mengambil tindakan yang diperlukan seperti memulihkan atau memulihkan mata uang kripto setelah pemberitahuan sebelumnya kepada pihak tersebut',
      ]
    },
    {
      title: 'Pasal 20 (Yurisdiksi dan Hukum yang Mengatur)',
      content: [
        '① Ketentuan Layanan ini akan diatur dan ditafsirkan sesuai dengan hukum Republik Korea, dan hukum Republik Korea akan berlaku jika terjadi gugatan yang timbul dari perselisihan antara Perusahaan dan pengguna sehubungan dengan penggunaan Layanan, termasuk sistem penetapan harga Perusahaan',
        
      ]
    },
    { title: 'Adendum', isi: 'Syarat dan Ketentuan ini berlaku mulai 1 Oktober 2020.' },
  ],

  ///////////////////////////////////////////////////////////////////////////////

  login: 'login',
  password: 'password',

  loginFailedTitle: 'Login gagal',
  loginGagalSalahId: 'Nama pengguna tidak valid',
  loginFailedWrongPassword: 'Kata sandi tidak valid',
  loginFailedExpiredId: 'ID ini sudah kedaluwarsa',
  loginFailedEmailConfirm: 'Email Anda belum diotorisasi, mohon konfirmasi email Anda sebelum menggunakan layanan ini',
  loginFailedMessage: 'Login gagal. Silakan coba lagi nanti',
  loginFailedInvalidLoginInfo: 'Informasi login tidak valid',

  myinfoLogout: 'Keluar',
  myinfoLogoutConfirm: 'Apakah Anda yakin ingin keluar?',
  passwordModify: 'Ubah kata sandi Anda',
  changePasswordFailed: 'Perubahan kata sandi gagal',
  changePasswordSuccess: 'Perubahan kata sandi Anda berhasil. Silakan lanjutkan untuk masuk',

  savedId:'id yang tersimpan',
  autoLogin:'Login otomatis',
  findPw: 'Temukan kata sandi',

  /*********My Information************/
  myPage:'Halaman saya',
  myWallet: "Dompet saya",
  exchange: "Penukaran",
  mining: "Penambangan",
  notice: "Pemberitahuan",
  myWalletDialog: "Detail dompet saya",
  walletReceiveMenu: "Terima dompet",
  walletSendMenu: "Kirim dompet",
  inquiry: "Pertanyaan 1:1",
  phone: "Nomor telepon",
  setupOtp: 'Siapkan OTP',
  changeOtp: 'Ubah OTP',
  certiKYC: 'Sertifikasi KYC',
  change: 'Lakukan perubahan',
  close: 'Tutup',
  saveData: 'Simpan data',
  saveData: 'Simpan data',
  nowPw: 'Kata sandi saat ini',
  newPw: 'Kata sandi baru',
  changePasswordFailedSamePassword: 'Kata sandi lama dan baru sama',
  changePasswordFailedInvalidPassword: 'Kata sandi tidak valid',
  myinfoTwoSecurityInfo: 'Kunci otentikasi dua faktor adalah kunci 6 digit yang memberikan keamanan ekstra saat menarik dana dari dompet Anda. \Silakan unduh Google OTP atau Authy dari toko aplikasi',
  myInfoOtpMail: 'Masukkan kode verifikasi 6 digit yang dikirim ke email Anda',
  otpCopied: 'Kunci OTP Anda telah disalin',
  submit: 'Kirim',
  inputPhone: 'Masukkan nomor telepon Anda',
  sendCertiCode2: 'Kirim kode verifikasi',
  phoneFail: 'Masukkan nomor telepon Anda dengan benar',
  checkCertiCode: 'Periksa kode autentikasi',
  certiCode: 'Kode otentikasi',
  certiCodeExpired: 'Otentikasi Anda telah kedaluwarsa, silakan kirim ulang kode otentikasi Anda',
  inputCertiCode: 'Masukkan kode autentikasi Anda',
  inputCurrentSecPassword: 'Masukkan kata sandi keamanan Anda saat ini (4 digit)',
  inputNewSecPassword: 'Masukkan kata sandi keamanan baru Anda (4 digit angka)',
  inputCurrentPassword: 'Masukkan kata sandi Anda saat ini',
  inputNewPassword: 'Masukkan kata sandi baru',
  kycOTPerror: 'Silakan mendaftar untuk OTP terlebih dahulu',
  certiCompleted: 'Otentikasi selesai',
  auditing:'Mengaudit',
  enterNicknameValidation: 'Masukkan nama Anda dalam bahasa Korea dan Inggris',
  setSucceed: 'Perubahan berhasil',
  setFailed: 'Perubahan gagal, silakan hubungi administrator',
  kycInfo: 'Silakan tulis "stakingHouse", tanggal hari ini, dan identitas Anda (email) di selembar kertas putih dan ambil foto diri Anda yang sedang memegang kertas tersebut dengan identitas Anda',
  alreadyOtp: 'Anda telah mendapatkan OTP',
  myinfoOtpComplete: 'Penyiapan OTP telah selesai',
  myinfoOtpError: 'Kode verifikasi atau kode OTP tidak valid',
  changeOtpComplete: 'Otentikasi selesai. Silakan atur ulang OTP Anda',
  captureError: 'Pengambilan gagal. Silakan coba lagi',
  fileUploadComplete: 'File Anda telah didaftarkan',
  fileUploadError: 'Pendaftaran file gagal, silakan hubungi administrator Anda',

  /*********Pertanyaan************/
  inquire: 'Hubungi kami',
  myInquiry: 'Pertanyaan saya',
  admin:'admin',

  /*********Pertukaran************/
  exchangeUseCoin: 'Gunakan Koin',
  exchangeReceiveCoin: 'Koin yang akan diterima',
  exchangeUseAmount: 'Jumlah penggunaan',
  exchangeReceiveAmount: 'Jumlah yang akan diterima',
  exchangeRate: 'Nilai tukar',
  coinNameZZ: 'ZZ',
  coinNameETH: 'ETH',
  RateInform: 'Dapat bervariasi tergantung pada harga pasar pada saat pertukaran',
  feeOneday1: 'Satu biaya gratis per hari',
  feeOneday2: '(jika tidak ada transaksi dalam 24 jam)',
  exchangeConfirmMessage: 'Apakah Anda yakin ingin menukarkan %s dengan %s?',
  shortTermSecPassword: 'Kata sandi keamanan',
  exchangeCoinInputAmount: 'Silakan masukkan jumlahnya',
  exchangeCoinSuccess: 'Anda telah berhasil mengajukan permintaan penukaran',
  InvalidFranchise: 'Informasi waralaba tidak valid',
  InvalidCoinType: 'Jenis koin tidak valid',
  
  question: 'Pertanyaan',
  answer: 'Jawaban',
  registInquiry: 'Daftarkan pertanyaan',
  regist: 'Daftar',
  registComplete: "Pertanyaan Anda telah terdaftar",
  writer: 'Penulis',
  inquiryDetails: 'Rincian pertanyaan'
};

export default string;
