
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl,} from "../api/httpClient";
import { useLocation, useNavigate } from "react-router-dom";
import con from '../const';
import { useSelector, useDispatch } from "react-redux";
import string from '../language/StringKo';

const Header = () => {
    
    const string = useSelector(state => state.stringReducer.string); 
    const location = useLocation();
    const navigate = useNavigate();
    const [currName, setCurrName] = useState('Currency');
    useEffect(()=>{
        requestCurrencyExchangeRate();
    },[]);

    const requestCurrencyExchangeRate = (hide) => {
        if (con.enableForeignExchangeRate) {
            console.log("##### Request currencyExchangeRate")
            return new Promise((resolve, reject) => {
                httpGet(httpUrl.currencyExchangeRate, [], {}, hide).then((result) => {
                    console.log("## Request currencyExchangeRate loaded")
                    result = result.data;
                    global.exchangeRate = [
                        1,
                        result.find(x => x.currency === 'KRWUSD').rate,
                        result.find(x => x.currency === 'KRWCNY').rate,
                        result.find(x => x.currency === 'KRWJPY').rate,
                    ]
                    resolve();
                }).catch(e => {
                    console.log("## requestCurrencyExchangeRate error: " + e)
                    reject();
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                global.exchangeRate = [1, 1, 1, 1];
                resolve();
            })
        }
    }

    const menus = [
        {
            idx: 1,
            name: string.currency,
            url: "/Currency",
        },
        {
            idx: 2,
            name: string.myWallet,
            url: "/MyWallet",
        },
        {
            idx: 3,
            name: string.exchange,
            url: "/Exchange",
        },
        {
            idx: 4,
            name: string.mining,
            url: "/Mining",
        },
        {
            idx: 5,
            name: string.notice,
            url: "/notice",
            parent: "/Currency",

        },
        {
            idx: 6,
            name: string.myWalletDialog,
            url: "/myWalletDialog",
            parent: "/MyWallet",

        },
        {
            idx: 7,
            name: string.walletReceiveMenu,
            url: "/walletReceive",
            parent: "/MyWallet",

        },
        {
            idx: 8,
            name: string.walletSendMenu,
            url: "/walletSend",
            parent: "/MyWallet",

        },
        {
            idx: 9,
            name: string.inquiry,
            url: "/inquiry",
            parent: "/Mypage",

        },
        {
            idx: 10,
            name: string.myPage,
            url: "/Mypage",               
        },
        
    ];
    const menuShow = menus.filter(x => x.idx <= 3 || x.idx > 9)
    const currentPage = menus.find(
        (x) => x.url === location.pathname
    );

    return (
        <div id="header">     
            <div className='pathName'>{currentPage.parent ? currentPage.parent.replace("/","") : location.pathname.replace("/", "")}</div>
            <div className='navi'>
                {menuShow.map((row) => {
                    return (
                        <div
                            key={row.idx}
                            onClick={() => navigate(row.url)}
                            className={"top-menu " + (row.idx === currentPage.idx || row.url === currentPage.parent ? "active" : "")}
                        >
                            <div className="top-text">{row.name}</div>
                            <div className={row.idx === currentPage.idx || row.url === currentPage.parent ? 'menudot active' : 'menudot'}/>                         
                        </div>
                    );
                })}
            </div>
        </div>

    )
}


export default Header;