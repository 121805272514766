const string = {
  completed: '완료',
  ok: '확인',
  cancel: '취소',
  fail: '실패',
  sent: '출금',
  sending: '출금중',
  received: '입금',
  receiving: '입금중',
  value: '금액',
  myValue: '보유 잔액',
  fee: '수수료',
  more : 'More',
  welcome: 'Staking House에 오신것을 환영합니다!!',
  localCoinUnit: ['원', 'USD', 'CNY', 'JPY'],
  error: '오류',
  errorDetail: '오류가 발생했습니다. 관리자에게 문의해주세요.',

  /*********signUp**********/
  signUp: '회원가입',
  signUpMsg01: '회원가입 요청이 완료되었습니다.',
  signUpMsg02: '가입하신 아이디(이메일)로 인증메일이 발송되었습니다.',
  signUpMsg03: '메일인증을 완료 후 로그인 하실 수 있습니다.',
  signUpRuleMsg01: '이미 가입되어 있는 이메일 주소입니다.',
  signUpRuleMsg02: '이메일 형식이 잘못되었습니다.',
  signUpRuleMsg03: '패스워드 복잡도를 만족하지 못하였습니다.',
  signUpRuleMsg04: '가입 처리 중 알수없는 오류가 발생하였습니다.',
  signUpRuleMsg05: '입력한 두개의 비밀번호가 다릅니다.',
  signUpRuleMsg06: '약관에 동의해 주세요.',
  signUpRuleMsg07: '영문, 숫자, 특수문자로 8~15자를 입력해주세요.',
  signUpRuleMsg08: '핸드폰번호를 정확하게 입력해주세요',
  signUpRuleMsg09: '이메일을 입력해주세요',
  signUpRuleMsg10: '비밀번호를 입력해주세요',
  signUpRuleMsg11: '비밀번호 확인을 입력해주세요',
  signUpRuleMsg12: '이름을 입력해주세요',
  signUpRuleMsg13: '전화번호를 입력해주세요',
  signUpRuleMsg14: '보안 비밀번호 숫자 4자리를 입력하세요',
  signUpRuleMsg15: '보안 비밀번호를 동일하게 입력하세요',

  signUpFailTitle: '회원가입 실패',
  signUpFail: '회원가입에 실패하였습니다.',
  signUpCancleMsg: '회원가입을 취소하고 메인페이지로 이동하시겠습니까?',
  signUpAgreement: '개인정보 수집 및 이용',
  signUpOtherLoginMsg: '<span style="color:#00aab6; font-weight:bold;">네이버</span>나 <span style="color:#00aab6; font-weight:bold;">카카오</span> 로그인을 사용하시면 <span style="color:#00aab6; font-weight:bold;">별도의 회원가입 없이 이용</span>하실 수 있습니다.',
  emailRule03: '이메일 형식을 입력해주세요.',
  cancle: '취소',
  idEmail: '아이디(이메일)',
  passwordRule: '비밀번호 (영문자,숫자,특수문자 조합 8~15자리)',
  passwordConfirm: '비밀번호 확인',
  name: '이름',
  inputTel: '전화번호 입력',
  inputSecurity: '보안 비밀번호 숫자 4자리',
  inputSecurityConfirm: '보안 비밀번호 확인',

  dupCheck: '중복확인',
  emailDuplCheckError: '이메일 중복확인에 실패하였습니다.',
  emailDuplCheckErrorEmailDupl: '이미 등록된 이메일입니다.',
  emailDuplCheckErrorInvalidEmail: '이메일 형식이 잘못되었습니다.',
  needEmailDuplCheck: '이메일 중복 확인이 필요합니다.',

  sendCertiCode: '인증요청',
  certiCodeFail: '인증번호를 입력해주세요.',
  certiCodeWrong: '인증번호를 잘못 입력하셨습니다.',
  certiCodeSendFail: '인증번호 발송에 실패하였습니다.',
  certiCodeNotVerified: '전화번호 인증이 필요합니다.',

  signUpErrorTitle: '지갑 생성 실패',
  signUpErrorIdDuplication: '이미 등록된 이메일입니다. 이메일을 확인해 주시기 바랍니다.',
  signUpErrorInvalidEmailType: '이메일 형식이 잘못되었습니다. 다시 확인해 주시기 바랍니다.',
  signUpErrorMaxPhone: '전화번호당 등록가능한 계정 수를 초과하였습니다.',
  signUpErrorMessage: '지갑 생성에 실패하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.',

  emailResent: '인증메일이 재발송되었습니다.',
  emailResendFailed: '인증메일 재발송에 실패하였습니다.',
  invalidEmail: '잘못된 이메일입니다.',
  emailAlreadyVerified: '이미 인증된 이메일입니다.',
  emailRecentlySent: '1분 이내에 메일을 재발송할 수 없습니다. 잠시후 다시 시도해주세요.',
  inputJoinEmail: '가입하신 이메일을 입력해 주세요.',

  emailResendGuide1: '혹시 인증메일을 받지 못하였거나 인증시간이 만료되셨나요?',
  emailResendGuide2: '아래 인증메일 재전송 버튼을 클릭하여 다시 인증을 요청하세요.',
  emailResend: '인증메일 재전송',
  
  findPasswordDescription: '가입한 이메일을 입력하면 비밀번호를 변경할 수 있는 링크가 이메일로 전송됩니다.',
  findPasswordEmailSentSuccess: '비밀번호 변경을 위한 이메일이 발송되었습니다.',

  findId: '아이디 찾기',
  mnemonicError: '아이디 찾기에 실패하였습니다.',

  /*********시세**********/
  criteriaCoinmarketcap: '(코인마켓캡 기준)',
  updated:'갱신',
  currency:'시세',
  notice:'공지사항',
  krw:'원',
  news:'뉴스',
  note1:'공지',
  
  /*********투자*************/
  miningAccued:'누적채굴',
  miningInvestment:'나의 투자자산',
  miningGiveInfo:'채굴된 ZZ Coin은 익일 01시(GMT+9)에 일괄 지급됩니다.',
  miningToday: '오늘 채굴 현황',
  miningDetail: '상세내역',
  miningDoInvestment: '투자하기',
  coinNameZZ: 'ZZ',
  coinNameETH: 'ETH',


  /*********거래**********/
  selectCoin: '코인을 선택해 주세요.',
  sendCoinInputAmount: '금액을 입력해주세요.',
  sendCoinInputAmountLow: '코인의 최소 출금 수량은 0.000001 입니다.',
  sendCoinInputFee: '수수료를 입력해주세요.',
  sendCoinInputAddress: '주소를 입력해주세요.',
  sendCoinInputAddress2: '주소(유효주소를 입력하시오.)',
  sendCoinLowBalance: '잔액이 부족합니다.',
  sendCoinCurrentBalace: '현재 잔액',
  sendCoinAvailableBalace: '가용 잔액',
  // sendCoinLowBalanceXrp: '(최소 20XRP 보유 필요)',
  sendCoinLowBalanceXrp: '',
  sendCoinLowFee: '수수료가 부족합니다.',
  sendCoinInputError: '송금정보 생성에 실패하였습니다.',
  sendCoinSuccess: '송금을 완료하였습니다.',
  sendCoinNeedConfirm: '유의사항을 확인후 체크해 주세요.',
  sendCoinConfirmDlgTtl: '보내기',
  sendCoinConfirmMessage: '아래와 같이 코인을 송금합니다. 한 번 송금을 하시면 취소가 불가능합니다. 송금하시겠습니까?',
  sendCoinWarnMessage: '요청한 이후 취소가 불가능합니다!',

  sendCoinCautionConfirmDescriptionEx: [
    '금액과 주소가 올바른지 다시 확인해주세요.',
    '코인 금액은 소수점 6자리를 기본으로 관리하며',
    '상황에 따라 0. 이하 금액은 오차가 발생할 수 있습니다.',
    '블록체인의 특성상 보내기를 한 후 취소할 수 없습니다.',
  ],

  sendCoinCautionConfirm: '송금 유의사항 확인',
  sendCoinCautionConfirm2: '유의사항에 동의합니다.',
  sendCoinCautionFee: '블록체인 전송을 위해 약 0.01 ETH 상당의 수수료가 발생합니다.',
  sendCoinCautionConfirmBtc: '비트코인(BTC),비트코인캐시(BCH),비트코인골드(BCG) 입출금시에는 각각 다른 암호화폐이므로 늘 유의해 주시길 바랍니다.',
  sendCoinCautionConfirmEth: '이더리움(ETH),이더리움클래식(ETC) 입출금시에는 각각 다른 암호화폐이므로 늘 유의해 주시길 바랍니다.',
  sendCoinCautionConfirmXrp: '리플(XRP)은 지갑에 반드시 20XRP이상을 보유해야합니다. 따라서 출금 시 보유 잔액 중 20XRP를 제한 나머지 금액만 출금할 수 있습니다.',
  sendCoinCautionConfirmErc20: '이더리움 토큰 전송 시 사용되는 이더 수수료는 네트워크 상황에 따라 설정한 값보다 많거나 적을 수 있습니다. 따라서 출금 시 원하는 수수료의 3배 만큼의 이더리움을 보유하고 있어야 합니다.',
  sendCoinCautionConfirmDescription: [
    '코인 금액을 소수점 6자리를 기본으로 관리하며 상황에 따라 0.0001 이하 금액은 오차가 발생할 수 있습니다.',
    '보내기 후 전송이 완료되기까지 1시간 이상의 시간이 소요될 수 있으며, 전송 상태는 거래내역에서 확인하실 수 있습니다.',
    '다른 거래소 지갑으로 입출금 하는 경우에는 해당 거래소 정책의 영향을 받을 수 있습니다.',
    '암호화폐의 특성상 보내기를 한 후 취소할 수 없습니다.',
    '보내기 전 주소를 꼭 확인해 주세요.',
    '이 코인과 동일한 코인의 주소로만 보내기가 가능합니다. 다른 코인의 주소로 잘못 보내는 경우 코인을 찾을 수 없습니다.',
    '블록체인의 과부하로 인한 입출금 지연 문제는 stakingHouse에서 처리가 불가능합니다.',
  ],
  sendCoinXrpUseTag: '태그사용',
  sendCoinFailedTitle: '코인 전송 실패',
  sendCoinFailed: '코인 전송에 실패하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
  sendCoinFailedInvalidAddress: '보내시는 주소가 잘못되었습니다.',
  sendCoinFailedInsufficientBalance: '잔액이 부족합니다.',
  sendCoinFailedLocked: '코인에 LOCK 이 걸려있습니다.',
  sendCoinFailedInvalidOtp: 'OTP 정보가 잘못되었습니다.',
  sendCoinFailedInvalidSecPassword: '인증정보가 잘못되었습니다.',
  sendCoinFailedNoSecPassword: '보안 비밀번호 4자리를 입력해 주시기 바랍니다.',
  sendCoinFailedInvalidDesTag: '존재하지 않는 Destination Tag 입니다.',
  sendCoinFailedOtpRequired: 'OTP 없이 보낼 수 있는 일 한도 금액을 초과하였습니다.',
  sendCoinFailedLimitExceed: '일 한도 금액을 초과하였습니다.',
  sendCoinFailedSecurityLevelRequired: 'OTP와 KYC를 완료하여야 송금이 가능합니다.',
  sendCoinFailedKycDay: 'KYC 완료 후 하루동안 코인을 전송할 수 없습니다.',
  sendCoinSuccessTitle: '코인 전송 요청 완료',
  includeAmount: '금액 반영',
  includedAmount: 'QR에 포함된 입금금액',
  includeAmountCaution: '금액이 반영된 QR코드는 타지갑에서는 호환되지 않습니다.',
  inputDestinationTag: 'Destination Tag 입력 (선택)',
  inputOtpValidation: 'OTP를 입력해 주세요.',
  feeRequestFailed: '수수료 조회에 실패하였습니다.',
  exemption: '면제(회원간 전송)',

  /*********거래내역**********/
  noCoins: '거래 정보가 없습니다.',
  tradeLog: '거래내역',
  walletSend: '보내기',
  walletReceive: '받기',
  selectCoin: '코인을 선택해주세요.',
  copyComplete: '복사되었습니다',
  address: '지갑주소',
  priceInsert: '금액입력',
  copyAddress: '주소복사',
  qrContainPrice: 'QR에 포함된 입금금액',
  depositAddress: '입금주소',
  insertAddress: "주소입력",
  insertAmount: "수량입력",
  insertMemo: "메모(선택사항)",
  securityPw: "보안 비밀번호",
  pleaseInsertAddress: "주소를 입력해주세요.",
  pleaseInsertAmount: "수량를 입력해주세요.",
  pleaseInsertSecurityPw: "보안비밀번호를 입력해주세요.",
  pleaseInsertOtp: "otp를 입력해주세요.",
  externalTrade: "외부",
  adjustAccount: "정산",
  recieveUsdtCaution: 'ERC20기반 USDT입니다. 다른 체인의 USDT를 입금하는 경우 찾을 수 없으니 유의해 주세요',


  /*********약관 등**********/
  policyView: '약관보기',
  needAgreement: '약관에 동의해 주세요',
  policyTitle: '개인정보 수집 및 이용약관',
  policyTitle2: '(필수) 개인정보 수집 및 이용약관',
  policyContent: [
    { title: '제1장 총칙', content: '' },
    {
      title: '제1조 (목적)',
      content:
        '이 약관은 stakingHouse이 제공하는 앱 및 지갑에서 제공하는 서비스(이하: 서비스)의 이용조건 및 절차에 관한 회사와 사용자 간의 권리 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.\n',
    },
    {
      title: '제2조 (약관의 명시, 설명과 개정)',
      content: [
        '① 이 약관의 내용은 회사의 서비스 등 기타의 방법으로 사용자에게 공지하고, 사용자가 이 약관에 동의함으로써 효력을 발생합니다.\n',
        '② 회사는 “약관의 규제에 관한 법률”, “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.\n',
        '③ 회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를 명시하여 현행 약관과 함께 회사 사이트의 초기화면이나 팝업 화면 또는 공지사항으로 그 적용일자 7일 이전부터 공지합니다. 다만, 사용자에게 불리하거나 중대한 내용의 변경의 경우에는 적용일자 30일 이전에 사용자에게 알립니다.\n',
        '④ 회사가 전항에 따라 개정약관을 공지하면서 사용자에게 7일간의 기간 내에 의사 표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 사용자가 명시적으로 거부의 의사표시를 하지 아니한 경우 사용자가 개정약관에 동의한 것으로 봅니다.\n',
        '⑤ 사용자가 개정약관의 적용에 동의하지 않는다는 명시적 의사를 표명한 경우 회사는 개정 약관의 내용을 적용할 수 없으며, 이 경우 사용자는 이용계약을 해지할 수 있습니다. 다만, 기존 약관을 적용할 수 없는 특별한 사정이 있는 경우에는 회사는 이용계약을 해지할 수 있습니다.\n',
      ]
    },
    {
      title: '제3조 (약관 외 준칙)',
      content:
        '회사는 서비스에 대하여 이 약관 외에 별도의 공지사항, 개별 약관, 개별 동의 사항, 이용안내, 운영정책, 세부지침 등(이하 통틀어 “세부지침”이라 합니다)을 정하여 이를 사용자에게 알리거나 동의를 받을 수 있으며, 세부지침 중 사용자의 동의를 받은 내용이 이 약관과 상충할 경우에는 “세부지침”이 우선하여 적용됩니다. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 “세부지침” 및 관련 법령 또는 상관례에 따릅니다.\n',
    },
    {
      title: '제4조 (용어의 정의)',
      content: [
        '①	이 약관에서 사용하는 용어의 정의는 다음과 같습니다.\n',
        '1.암호화폐: 비트코인(BTC), 이더리움(ETH) 등 블록체인에서 전자적으로 존재하는 화폐를 말합니다.\n',
        '2.사용자: 이 약관을 승인하고 회사가 제공하는 지갑을 등록한 이용자를 말합니다.\n',
        '3.앱: 암호화폐를 저장하거나 송수신 할 수 있는 스마트폰 온라인 전용 프로그램을 말합니다.\n',
        '4.지갑: 스마트폰에 암호화폐를 저장 할 수 있는 앱을 말합니다.\n',
        '5.비밀번호: 사용자와 지갑간의 동일성 확인과 암호화폐 보안을 위하여 사용자가 설정한 문자의 조합을 말합니다.\n',
        '6.복구단어: 지갑 분실시 복구할 수 있는 12개의 암호화된 단어를 말합니다.\n',
      ]
    },
    {
      title: '제2장 서비스 이용 신청 및 승낙 (사용자가입 및 해지)',
      content: '',
    },
    {
      title: '제5조 (이용계약의 성립)',
      content: [
        '①	이용자는 회사가 제공하는 앱에 지갑 생성 시 이 약관에 동의한다는 의사표시를 함으로서 지갑 사용을 신청합니다.\n',
        '②	이용 계약은 지갑을 등록한 단위로 체결합니다. 이용계약이 성립되면, 이용 신청자는 지갑 사용자로 등록됩니다.\n'
      ]
    },
    {
      title: '제6조 (이용신청)',
      content: '이용신청은 회사가 제공하는 앱에 지갑 등록시 자동으로 이용신청이 승인됩니다.\n',
    },
    {
      title: '제7조 (사용자정보 사용에 대한 동의 및 이용신청의 승낙)',
      content: [
        '① 사용자정보 사용에 대한 동의\n',
        '1.회사는 사용자의 개인정보를 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.\n',
        '2.사용자가 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 사용자 정보는 회사와 제휴한 업체에 제공될 수 있습니다. 단, 회사는 사용자 정보 제공 이전에 제휴 업체, 제공 목적, 제공할 사용자 정보의 내용 등을 사전에 공지하고 사용자의 동의를 얻어야 합니다.\n',
        '3.사용자는 사용자 정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.\n',
        '4.사용자가 이용신청서에 사용자정보를 기재하고, 회사에 본 약관에 따라 이용신청을 하는 것은 회사가 본 약관에 따라 이용신청서에 기재된 사용자정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.\n',
      ]
    },
    {
      title: '제8조 (이용계약의 중지 및 해지)',
      content: [
        '①이용계약 및 앱 사용의 해지는 사용자가 스마트폰에서 앱을 삭제함으로써 해지가 가능합니다.\n',
        '② 회사는 가입신청자의 신청에 대하여 아래 각 호에 해당하는 사유가 있는 경우에는 승낙을 하지 않을 수 있으며, 가입 이후에도 아래 각 호의 사유가 확인될 경우에는 승낙을 취소하거나 이용계약을 해지할 수 있습니다.\n',
        '1. 본 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우\n',
        '2. 허위 또는 잘못된 정보를 기재 또는 제공하거나, 회사가 제시하는 내용을 기재하지 않은 경우\n',
        '3. 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 본 약관에서 규정한 제반 사항을 위반하며 신청한 경우\n',
        '4. 부정한 용도 또는 별개의 영업 목적으로 서비스를 이용하고자 하는 경우\n',
        '5. 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우\n',
        '6. 가입신청자의 나이가 만 19세 미만인 경우\n',
        '③ 회사는 회원이 제공한 정보가 사실과 일치하는 지 여부를 확인하기 위하여 법령에 의하여 허용된 범위에서 전문기관을 통한 실명확인 또는 본인인증을 요청할 수 있습니다.\n',
      ]
    },
    { title: '제3장 회사의 의무', content: '' },
    {
      title: '제9조 사용자 개인정보와 서비스에 대한 회사의 의무',
      content: [
        '① 회사는 관련 법령과 본 약관을 준수하며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.\n',
        '② 회사는 사용자가 안전하게 서비스를 이용할 수 있도록 개인정보보호를 위해 보안시스템을 갖출 수 있으며, 개인정보처리방침을 공시하고 준수합니다.\n',
        '③ 회사는 업무와 관련하여 사용자의 사전 동의 없이 사용자 전체 또는 일부의 개인정보 등을 특정 사용자의 정보를 구별할 수 없는 통계자료로 작성하여 이를 사용할 수 있고, 이를 위하여 사용자는 컴퓨터에 쿠키를 전송할 수 있습니다. 다만, 이 경우 사용자는 쿠키의 송신을 거부하거나 쿠키의 송신에 대하여 경고하도록 사용하는 기기의 설정을 변경할 수 있으나, 이 경우 쿠키의 설정 변경으로 인해 회사의 귀책사유 없이 서비스 이용이 변경될 수 있습니다.\n',
        '④ 회사는 서비스 이용과 관련하여 사용자로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, 서비스 내 게시판, E-mail 등을 통하여 사용자에게 처리과정 및 결과를 전달할 수 있습니다.\n',
        '⑤ 회사는 정보통신망법, 위치정보보호법, 통신비밀보호법, 전기통신사업법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.\n',
      ]
    },
    { title: '제4장 사용자의 의무', content: '' },
    {
      title: '제10조 (사용자 지갑과 비밀번호 관리에 대한 사용자의 의무)',
      content: [
        '① 지갑과 비밀번호에 관한 모든 관리책임은 사용자에게 있습니다. 사용자가 등록한 지갑과 비밀번호의 관리소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 사용자에게 있으며, 회사는 이에 대한 책임을 지지 않습니다. 다만, 회사의 고의로 인한 경우에는 그러하지 아니합니다.\n',
        '② 12개의 복구 단어는 반드시 종이에 기입 해 놓아야 하며 지갑을 분실하여 복구 할때 12개의 복구 단어가 필요합니다. 회사는 이 정보를 가지고 있지 않기 때문에 복구단어가 분실하는 경우에 복구가 불가능한데 이때 모든 책임은 사용자에게 있으며 회사는 이에 대한 책임을 지지 않습니다. 또한 본인의 실수로 인해 12개의 복구 단어가 유출되어 입은 손실도 모든 책임은 사용자에게 있으며 회사는 이에 대한 책임을 지지 않습니다.\n',
      ]
    },
    {
      title: '제11조 (정보의 제공)',
      content: [
        '1. 회사는 유∙무선전화, 전자우편, 문자서비스(LMS/SMS), SNS 등의 방법으로 다음과 같은 서비스에 대해서 이용편의를 위한 이용안내 및 상품에 관한 정보를 제공할 수 있습니다. 이 경우 사용자는 언제든지 수신을 거부할 수 있습니다.\n',
        '① 이벤트 및 행사관련 등의 서비스\n',
        '② 기타 회사가 수시로 결정하여 사용자에게 제공하는 서비스\n',
        '2. 사용자는 서비스를 통하여 언제든지 본인의 개인정보를 열람하고 수정 또는 수정 요청할 수 있습니다. 사용자는 회원가입 신청 시 기재한 사항이 변경되었을 경우 서비스에서 직접 수정하거나, 고객센터를 통하여 회사에 변경 사항을 통지하여야 합니다. 사용자는 전항의 변경사항을 회사에 통지하지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.\n',
      ]
    },
    { title: '제5장 서비스 이용 총칙', content: '' },
    {
      title: '제12조 (서비스의 종류)',
      content: [
        '① 회사에서 제공하는 서비스에는 암호화폐를 저장하는 지갑기능, 다른 사람에게 암호화폐를 송수신 할수 있는 기능 등이 있습니다. 단, 사용자가 제공하는 암호화폐 지갑 주소의 정확성에 대한 책임은 사용자에게 있으며, 회사는 사용자가 잘못 기재한 암호화폐 지갑 주소로 암호화폐를 보내는 것에 대해서 책임을 지지 않습니다.\n',
        '② 회사가 제공하는 서비스의 종류는 회사의 사정에 의하여 수시로 변경될 수 있으며, 제공되는 서비스에 대한 저작권 및 지식재산권은 회사에 귀속됩니다.\n',
        '③ 회사는 서비스와 관련하여 사용자에게 회사가 정한 이용조건에 따라 계정, 지갑, 서비스 등을 이용할 수 있는 이용권한만을 부여하며, 사용자는 이를 활용한 유사 서비스 제공 및 상업적 활동을 할 수 없습니다.\n',
      ]
    },
    {
      title: '제13조 (서비스 내용의 공지 및 변경)',
      content: [
        '① 회사는 서비스의 종류에 따라 각 서비스의 특성, 절차 및 방법에 대한 사항을 웹사이트를 통하여 공지하며, 사용자는 회사가 공지한 각 서비스에 관한 사항을 이해하고 서비스를 이용해야 합니다.\n',
        '② 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 개별 서비스의 전부 또는 일부를 변경할 수 있습니다. 이 경우 회사는 최소 7일전에 해당 내용을 이용자에게 알립니다.\n',
      ]
    },
    {
      title: '제14조 (서비스의 유지 및 중지)',
      content: [
        '① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 사전 혹은 사후에 고지하여 정한 날이나 시간은 그러하지 않습니다.\n',
        '② 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전 혹은 사후에 공지합니다.\n',
        '③ 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. 이 경우 회사는 불가피한 경우가 아닌 한 서비스 제공 중지를 사전 고지합니다.\n',
        '1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우\n',
        '2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우\n',
        '3. 회사가 직접 제공하는 서비스가 아닌 제휴업체 등의 제3자를 이용하여 제공하는 서비스의 경우 제휴 업체 등의 제3자가 서비스를 중지했을 경우\n',
        '4. 기타 불가항력적 사유가 있는 경우\n',
        '④ 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.\n',
      ]
    },
    {
      title: '제 15 조 이용제한',
      content: [
        '① 회사는 다음 각 호에 해당하는 경우 회원의 서비스 이용 및 접속을 제한할 수 있습니다.\n',
        '1. 비밀번호 및 보안비밀번호 연속 오류의 경우\n',
        '2. 해킹 및 사고가 발생한 경우\n',
        '3. 명의 도용으로 의심되는 경우\n',
        '4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우\n',
        '5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우\n',
        '6. 회원으로 가입된 이용자가 만 19세 미만으로 확인된 경우\n',
        '7. 기타 각호에 준하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우\n',
        '② 회사는 다음 각 호에 해당하는 경우 회원의 입금 및 출금 이용을 제한할 수 있습니다.\n',
        '1. 회원으로 가입된 이용자가 만 19세 미만으로 확인된 경우\n',
        '2. 해킹 및 사기 사고가 발생하였거나 발생한 것으로 의심되는 경우\n',
        '3. 명의 도용으로 의심되는 경우\n',
        '4. 국가기관이 관련 법령에 따라 서비스 제한을 요청하는 경우\n',
        '5. 자금세탁, 불공정거래, 범죄행위 등에 관여하고 있거나 관여하고 있다고 합리적으로 의심되는 경우\n',
        '6. 회원 본인의 요청이 있는 경우\n',
        '7. 암호화폐가 송금되었으나 회원 계정에 반영되지 않은 경우 또는 실제 입출금 요청과 다르게 입출금이 이루어진 경우\n',
        '8. 기타 각호에 준하는 사유에 해당하는 사유가 발생하거나 그러한 사유 발생의 방지를 위한 조치가 필요한 경우\n',
        '③ 본 조에 따라 서비스 이용을 제한하거나 이용계약을 해지하는 경우에는 회사는 E-mail, SMS, App Push 등으로 회원에게 통지합니다.\n',
        '④ 회원은 본 조에 따른 이용제한 등에 대해 회사가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 회사가 인정하는 경우 회사는 즉시 서비스의 이용을 재개합니다.\n',
      ]
    },
    {
      title: '제 16 조 (이용계약 해지)',
      content: [
        '① 사용자는 언제든지 고객센터를 통하여 이용계약 해지를 신청할 수 있으며, 회사는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.\n',
        '② 회사는 회원에게 다음과 같은 사유가 발생할 경우 시간을 정하여 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 반복적으로 동일한 위반행위를 하는 경우에는 이용계약을 해지할 수 있습니다.\n',
        '1. 본 약관 제10조에 규정된 사용자의 의무를 위반하거나 제15조에 규정된 이용제한 사유에 해당하는 경우\n',
        '2. 저작권법을 위반한 불법 프로그램의 제공 및 운영 방해, 정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법 통신 및 해킹, 악성프로그램의 배포, 접속 권한 초과 행위 등과 같이 관련 법령을 위반한 경우\n',
        '3. 회사가 제공하는 서비스의 원활한 진행을 방해하는 행위를 하거나 시도한 경우\n',
        '4. 그 밖에 각 호에 준하는 사유로서 본 계약을 유지할 수 없는 사유가 발생하는 경우\n',
        '③ 전항에 따른 이용계약 해지 시 서비스 이용을 통해 획득한 모든 혜택이 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.\n',
        '④ 본 조에 따라 서비스 이용계약을 해지하는 경우에는 회사는 사용자에게 E-mail, SMS, App Push 등으로 통지합니다.\n',
        '⑤ 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라 회사가 보유하여야 하는 정보를 제외한 사용자의 모든 정보가 삭제됩니다.\n',
        '⑥ 제2항에 따라 회사가 이용계약을 해지하는 경우 회사는 사용자의 이의신청 접수 및 처리 등을 위하여 일정 기간 동안 사용자의 정보를 보관할 수 있으며, 해당 기간이 경과한 후에 사용자의 정보(단, 입출금 내역은 제외)를 삭제합니다.\n',
      ]
    },
    {
      title: '제 17 조 (정보의 제공 및 광고의 게재)',
      content: [
        '① 회사는 서비스를 운영함에 있어서 서비스 관련 각종 정보를 서비스 화면에 게재하거나 E-mail, SMS, App Push 등의 방법으로 사용자에게 제공할 수 있습니다.\n',
        '② 회사는 서비스를 운영함에 있어 회사 또는 제휴사의 각종 광고를 서비스 화면에 게재하거나 사용자의 동의를 얻어 E-mail, SMS, App Push 등의 방법으로 사용자에게 제공할 수 있습니다',
      ]
    },
    { title: '제5장 개인정보 보호', content: '' },
    {
      title: '제18조 (개인정보의 보호)',
      content: [
        '회사는 관계 법령이 정하는 바에 따라 사용자의 개인정보를 보호하기 위해 노력합니다. 사용자의 개인정보는 서비스의 원활한 제공을 위하여 사용자가 동의한 목적과 범위 내에서만 이용됩니다. 회사는 법령에 의하거나 사용자가 별도로 동의하지 아니하는 한, 사용자의 개인정보를 제3자에게 제공하지 아니하며, 이에 대한 자세한 사항은 개인정보 처리방침에서 정합니다.\n',
      ]
    },
    { title: '제6장 손해배상 및 면책조항', content: '' },
    {
      title: '제19조 (회사의 면책사항 및 손해배상)',
      content: [
        '① 회사는 서비스와 관련하여 이 약관에 명시되지 않은 어떠한 사항에 대하여 보증을 하지 않습니다. 또한, 회사는 회사가 발행하거나 지급을 보증하지 아니한 암호화폐의 가치를 보증하지 않습니다.\n',
        '② 회사는 천재지변, 디도스(DDos) 공격, IDC장애, 서비스 접속의 폭등으로 인한 서버 다운, 기간통신사업자의 회선 장애 등 기타 불가항력적인 사유로 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '③ 회사는 블록체인의 문제, 암호화폐 발행 관리 시스템 자체의 하자 또는 기술적 문제, 통신서비스 업체의 불량, 정기적인 서버점검 등으로 인하여 불가피하게 장애가 발생하였을 경우에 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '④ 회사는 사용자의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여는 책임을 지지 않습니다. 다만, 사용자에게 정당한 사유가 있는 경우에는 그러하지 아니합니다.\n',
        '⑤ 회사는 사용자간 또는 사용자과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.\n',
        '⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.\n',
        '⑦ 회사는 안정적인 서비스 제공을 위해 정기적, 비정기적 또는 긴급한 서버 점검을 실시할 수 있습니다.\n',
        '⑧ 누구든지 서비스 오류, 전산장애 또는 기타 사유로 인하여 제3자 소유의 암호화폐를 권한 없이 취득하거나 전송 받게 될 경우, 회사는 당사자에게 사전 통지 후 해당 암호화폐를 회수하거나 원상회복 시키는 등 필요한 조치를 취할 수 있습니다.\n',
      ]
    },
    {
      title: '제20조 (관할법원 및 준거법)',
      content: [
        '① 본 서비스 이용 약관은 대한민국 법률에 따라 규율되고 해석되며, 회사의 요금체계 등 서비스 이용과 관련하여 회사와 사용자 간에 발생한 분쟁으로 소송이 제기되는 경우 대한민국 법률이 적용됩니다.\n',
        '② 서비스 이용과 관련하여 회사와 사용자 간에 발생한 소송의 경우 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.\n',
      ]
    },
    { title: '부칙', content: '본 약관은 2020년 10월 1일부터 적용됩니다.' },
  ],

  ///////////////////////////////////////////////////////////////////////////////

  login: '로그인',
  password: '비밀번호',

  loginFailedTitle: '로그인 실패',
  loginFailedWrongId: '잘못된 아이디입니다.',
  loginFailedWrongPassword: '잘못된 비밀번호 입니다.',
  loginFailedExpiredId: '사용이 중지된 아이디입니다.',
  loginFailedEmailConfirm: '이메일 승인이 되지 않았습니다. 이메일 승인 후 이용해 주시기 바랍니다.',
  loginFailedMessage: '로그인에 실패하였습니다. 잠시 후 다시 시도해 주시기 바랍니다.',
  loginFailedInvalidLoginInfo: '로그인 정보가 잘못되었습니다.',

  myinfoLogout: '로그아웃',
  myinfoLogoutConfirm: '로그아웃 하시겠습니까?',
  passwordModify: '비밀번호 변경',
  changePasswordFailed: '비밀번호 변경에 실패하였습니다.',
  changePasswordSuccess: '비밀번호 변경이 완료되었습니다. 로그인을 진행해 주시기 바랍니다.',

  savedId:'아이디 저장',
  autoLogin:'자동 로그인',
  findPw:'비밀번호 찾기',

  /*********내 정보************/
  myPage:'내 정보',
  myWallet: "내 지갑",
  exchange: "교환",
  mining: "채굴",
  notice: "공지사항",
  myWalletDialog: "지갑상세",
  walletReceiveMenu: "지갑받기",
  walletSendMenu: "지갑보내기",
  inquiry: "1:1 문의",
  phone:'전화번호',
  setupOtp: 'OTP 설정',
  changeOtp: 'OTP 변경',
  certiKYC:'KYC인증',
  change: '변경하기',
  close:'닫기',
  saveData:'저장하기',
  nowPw:'현재 비밀번호',
  newPw: '새 비밀빈호',
  changePasswordFailedSamePassword: '기존 비밀번호와 새 비밀번호가 동일합니다.',
  changePasswordFailedInvalidPassword: '비밀번호가 틀렸습니다.',
  myinfoTwoSecurityInfo: '이중인증 키는 지갑에서 출금시, 보안강화 시키는 6자리 키입니다. \n앱 스토어에서 Google OTP 혹은 Authy 다운로드 해주세요.',
  myInfoOtpMail : '메일로 발송된 \n 인증코드 6자리를 입력해주세요',
  otpCopied: 'OTP 키가 복사되었습니다.',
  submit:'제출하기',
  inputPhone: '전화번호 입력',
  sendCertiCode2: '인증번호 발송',
  phoneFail: '휴대전화 번호를 정확히 입력해주세요.',
  checkCertiCode: '인증번호 확인',
  certiCode: '인증번호',
  certiCodeExpired: '인증 기한이 초과되었습니다. 인증번호를 다시 전송하시기 바랍니다.',
  inputCertiCode: '인증번호 입력',
  inputCurrentSecPassword: '현재 보안 비밀번호 입력 (4자리 숫자)',
  inputNewSecPassword: '새로운 보안 비밀번호 입력 (4자리 숫자)',
  inputCurrentPassword: '현재 비밀번호 입력',
  inputNewPassword: '새로운 비밀번호 입력',
  kycOTPerror:'OTP등록을 먼저 해주세요',
  certiCompleted:'인증 완료',
  auditing:'심사 중',
  enterNicknameValidation: '이름은 한글과 영문으로 입력해주세요',
  setSucceed: '변경이 완료되었습니다.',
  setFailed: '변경에 실패하였습니다. 관리자에게 문의해주세요',
  kycInfo:'흰종이에 "stakingHouse"과 오늘 날짜, 아이디(이메일)를 적은 뒤 신분증과 함께 들고 얼굴이 나오도록 사진을 촬영해주세요',
  alreadyOtp: 'OTP를 이미 발급 받았습니다.',
  myinfoOtpComplete: 'OTP 설정 완료 됐습니다.',
  myinfoOtpError: '인증코드 또는 OTP코드가 잘못되었습니다',
  changeOtpComplete: '인증을 완료했습니다. OTP를 다시 설정해주세요.',
  captureError:'촬영에 실패하였습니다. 다시 촬영해주세요',
  fileUploadComplete: '파일이 등록되었습니다.',
  fileUploadError: '파일 등록을 실패했습니다. 관리자에게 문의해주세요',

  /*********문의하기************/
  inquire : '문의하기',
  myInquiry: '내 문의',
  admin:'관리자',

  /*********교환************/
  exchangeUseCoin: '사용 코인',
  exchangeReceiveCoin: '받을 코인',
  exchangeUseAmount: '사용 수량',
  exchangeReceiveAmount: '받을 수량',
  exchangeRate: '환율',
  coinNameZZ: 'ZZ',
  coinNameETH: 'ETH',
  RateInform: '교환 시점의 시세에 따라 달라질 수 있습니다.',
  feeOneday1: '하루 1회 수수료 무료',
  feeOneday2: '(24시간 이내 거래가 없는경우)',
  exchangeConfirmMessage: '%s를 %s로 교환하시겠습니까?',
  shortTermSecPassword: '보안 비밀번호',
  exchangeCoinInputAmount: '수량을 입력해주세요.',
  exchangeCoinSuccess: '교환를 요청하였습니다.',
  InvalidFranchise: '가맹점 정보가 잘못되었습니다.',
  InvalidCoinType: '코인 종류가 잘못되었습니다.',
  
  question: '질문',
  answer: '답변완료',
  registInquiry: '문의등록',
  regist: '등록',
  registComplete: "문의 내용이 등록 되었습니다.",
  writer: '작성자',
  inquiryDetails: '문의 내용'
};

export default string;
