import { Modal, Popover } from "antd";
import React, {useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLogout, stringReducer } from "../actions";
import { useNavigate } from "react-router-dom";
import logo from "../img/text-logo1.png";
import global from "../img/common/global.png";
import logoutimg from "../img/common/logout.png";

const Top = (props) => {
  const dispatch = useDispatch();
  const language = useSelector(state => state.stringReducer.language);
  const string = useSelector(state => state.stringReducer.string);
  const navigate = useNavigate();
  const [globalOpen, setGlobalOpen] = useState(false);
  const logout = () => {
    Modal.confirm({
        title: string.myinfoLogout,
        content: string.myinfoLogoutConfirm,
        okText: string.ok,
        cancelText: string.cancel,
        onOk() {
            dispatch(setLogout());
            navigate('/', {state: { logout: true}});
        },
        onCancel() {
        },
    });
  }

  const changeLan = (lan) =>{ 
    dispatch(stringReducer(lan)) 
  };  

  return(
    <div id="top">
      <img src={logo} style={{width: 130}}/>  
      <div className="iconContainer">
        <Popover                
          content={
              <div className='pophover'>
                  <div onClick={()=>changeLan('ko')} className={language == 'ko' ? "lan active": "lan"}>Korean</div>
                  <div onClick={()=>changeLan('en')} className={language == 'en' ? "lan active": "lan"}>English</div>
                  <div onClick={()=>changeLan('ja')} className={language == 'ja' ? "lan active": "lan"}>Japanese</div>
                  <div onClick={()=>changeLan('id')} className={language == 'id' ? "lan active": "lan"}>Indonesian</div>
              </div>                    
          }
          trigger={"click"}
          open={globalOpen}
          onOpenChange={()=>setGlobalOpen(!globalOpen)}>
          <img src={global} className='globalImg'/>
        </Popover>    
        <div onClick={logout}>
          <img src={logoutimg} className='globalImg' style={{ marginTop: 4 }}/>  
        </div>
      </div>
    </div>    
  )
}

export default Top;