import { Input, Button, Modal, Popover } from 'antd';
import React, { useRef, useState } from 'react';
import { httpUrl, httpPost } from "../api/httpClient";
import { stringReducer,} from "../actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MailOutlined, SmileOutlined } from '@ant-design/icons';
import global from "../img/common/global.png";
import logo from "../img/text-logo2.png";

const ModifyPassword = () => {
    const formRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);    
    const [email, setEmail] = useState('');
    const [step, setStep] = useState(0);
    const [globalOpen, setGlobalOpen] = useState(false);
    const handleSubmit = async () => {      
        console.log()
        const res = await httpPost(httpUrl.userFindPassword, [email, language], {});
        if (!res.data) {
            Modal.error({
                title: string.findId,
                content: string.mnemonicError,
            });          
        } else {
           setStep(1);
        };           
    }
    const changeLan = (lan) =>{ dispatch(stringReducer(lan)) };
    return (
        <div id="modifyPw">
            <div className='header'>
                <Popover                
                    content={
                        <div className='pophover'>
                            <div onClick={()=>changeLan('ko')} className={language == 'ko' ? "lan active": "lan"}>Korean</div>
                            <div onClick={()=>changeLan('en')} className={language == 'en' ? "lan active": "lan"}>English</div>
                            <div onClick={()=>changeLan('ja')} className={language == 'ja' ? "lan active": "lan"}>Japanese</div>
                            <div onClick={()=>changeLan('id')} className={language == 'id' ? "lan active": "lan"}>Indonesian</div>
                        </div>                    
                    }
                    trigger={"click"}
                    open={globalOpen}
                    onOpenChange={()=>setGlobalOpen(!globalOpen)}>
                    <img src={global} className='globalImg'/>
                </Popover>
            </div>
            <div className='container'>
                <img src={logo} className='logoImg'/>
                {step === 0 ?   
                <>  
                    <div className='info' style={{ fontSize: 16, color: '#888', wordBreak:'keep-all', textAlign:'center', marginBottom: 20 }}>
                        {string.findPasswordDescription}
                    </div>
                    <div className='formBox'>
                        <input className="login-input"
                            autoComplete='false'
                            placeholder={string.idEmail}
                            style={{textAlign:'center'}}
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </div>      
                    <div className='btnBox'>                             
                        <button className='loginBtn' type="primary" htmlType="submit" onClick={handleSubmit}>{string.ok}</button> 
                    </div>         
                    <div className='cancel' onClick={()=>navigate(-1)}>{string.cancel}</div>
                </> 
                :    
                <div className='findPwResult'>
                    <SmileOutlined className='icon'/>
                    <p>{string.findPasswordEmailSentSuccess}</p>
                    <div className='btnBox'>                             
                        <button className='loginBtn' type="primary" htmlType="submit" onClick={() => navigate('/')}>{string.login}</button> 
                    </div>                      
                </div>             
                }
              
            </div>                      
        </div>
    )
}
export default ModifyPassword;