
import { Form, Input, Checkbox, Button, Modal, Table, Divider, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { httpGet, httpUrl, httpPost } from "../api/httpClient";
import crypto, { timingSafeEqual } from 'crypto-js';
import { useDispatch, useSelector } from "react-redux";
import { stringReducer, setLogin, setLogout, setCoinCurrency, setCoinList } from "../actions";
import { Link, useNavigate, withRouter } from "react-router-dom";
import { formatBalance } from "../lib/util/coinUtil";
import string from '../language/StringKo';
import moment from "moment";
import { comma } from '../lib/util/numberUtil';
import Header from '../components/Header';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import down from "../img/common/arrow-down.png";
import up from "../img/common/arrow-up.png";

import Top from './Top';
const FormItem = Form.Item;

const Currency = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const coinList = useSelector(state => state.coinListReducer);   
    const language = useSelector(state => state.stringReducer.language);
    const string = useSelector(state => state.stringReducer.string);   
    const [list, setList] = useState([]);
    const [balances, setBalances] = useState([]);
    const [blockchains, setBlockchains] = useState([]);
    const [refreshing, setRefreshing] = useState(false)
    const [coinCurrencyUpdateTime, setCoinCurrencyUpdateTime] = useState('');
    const [graphOpen, setGraphOpen] = useState(0);
    const [currencyLoaded, setCurrencyLoaded] = useState(false);
    let currency =  global.currency;
    let exchangeRate =  global.exchangeRate;

    const onClickCoinInfo = (coin) => {
        if (graphOpen == coin.coinType) setGraphOpen(0);
        else setGraphOpen(coin.coinType);
    }

    const requestCurrency = (hide) => {
        let target
        let check
        var options = { hour: "numeric", minute: "numeric", second: "numeric", hour12: false };
        return new Promise((resolve, reject) => {
            httpGet(httpUrl.currencies, [], {}, hide).then((result) => {
                // this.setState({ blockchains: this.state.blockchains},() => console.log("dddddddddddddddddddd " + JSON.stringify(this.state.blockchains)))
                dispatch(setCoinCurrency(result.data));
                setCoinCurrencyUpdateTime(new Date().toLocaleTimeString("en-US", options));
                setCurrencyLoaded(true);
                resolve();
            }).catch(e => {
                console.log("## requestCurrency error: " + e);
                reject(e);
            });
        })
    }

    const getNoticeList = () => {
        httpGet(httpUrl.noticeList, [1, 3, language], {})
            .then(res => {
                console.log(res)
                setList(res.data.noticeList)
            })
         
    }

    const handleRefresh = () => {
        setRefreshing(true)
        requestCurrency(true).then(() => {
            setRefreshing(false);
            setCoinCurrencyUpdateTime(new Date().toLocaleTimeString())

        }).catch(e => {
            setRefreshing(false)
            console.log("## requestCurrency error: " + e)
        });
    }

    useEffect(()=>{
       requestSystemListBlockchain();
        requestBalance();
        getNoticeList();
    },[])

    const requestBalance = () => {
        console.log("##### Request balance")
        // httpGet(httpUrl.balance, [0], {}).then((result) => {
        //     result = result.data;
        //     console.log(result)
        //     setBalances(result)
        // }).catch(e => {
        //     console.log("## requestBalance error: " + e)
        // });
    }
    const requestSystemListBlockchain = () => {
        console.log("## Loading systemListBlockchain");
        httpGet(httpUrl.systemListBlockchain, [language], {}).then(async (result) => {
            result = result.data;
            result.forEach(obj => {
                obj.status = true;
                obj.address = '';
                obj.balance = 0;
                obj.lockBalance = 0;
                obj.balanceLoaded = false;
                obj.price = 0;
                obj.priceLoaded = false;
                obj.value = 0;
                obj.valueLoaded = false;
                obj.purchaseFee = 0;
                obj.sendInFee = 0;
                obj.sendExFee = 0;
                obj.tradeFee = 0;
                obj.feeLoaded = false;
                obj.serverWallet = '';
                obj.serverWalletLoaded = false;
                obj.change = 0.0;
                obj.market = 0.0;
                obj.trends = [];
            })
            console.log('## systemListBlockchain loaded');
            dispatch(setCoinList(result));
            await requestCurrency();

        }).catch((e) => {
            console.log('## systemListBlockchain load error: ' + e)
        });
    }

    const  formatXAxis = (item) => {
        return item
        // return `${item.slice(0, 4)}`
    };
    const formatYAxis = (item) => item.toLocaleString();
    const formatTooltip = (item) => item.toLocaleString();

    return (
        <div id="currency">
            <Top />
            <Header />
            <div className='notiTitle'>
                <div className="mainTitle">{string.notice}</div>     
                <div className='moreBtn' onClick={()=>navigate('/notice')}>+</div>
            </div>
            <div className='notiTableContainer'>
                {list.map((item)=>{
                    return(
                        <div className='notiTable'>
                            <div className='idx'>{item.idx}</div>
                            <div className='title'>{item.title}</div>
                            <div className='data'>{moment(item.createDate).format("DD/MM/YYYY")}</div>
                        </div>
                    )
                })}
            </div>
            <div className='notiTitle' style={{marginTop: 30}}>
                <div className="mainTitle">{string.currency}</div>
                <div className='subTitle'>{coinCurrencyUpdateTime} {string.updated} {string.criteriaCoinmarketcap}</div>
            </div>
            <div className='coinTableContainer'>
            {coinList.map((value, index, item, target) => {
                let changeColor = '#fff';
                let changeBackColor = '#000';
                let arrowImage = "";

                if (value.change < 0) {
                    changeColor = '#37ABFF';
                    changeBackColor = '#fff';
                    arrowImage = down;
                }
                if (value.change > 0) {
                    changeColor = '#D50000';
                    changeBackColor = '#fff';
                    arrowImage = up;
                }
                let balanceRow = balances.find(x => x.coinType == value.coinType);
                let balance = 0;
                if (balanceRow != null) balance = balanceRow.balance;
                let sortedDate = value.trends.sort((a,b)=>a.logDate-b.logDate)
                return (
                    <div className={graphOpen == value.coinType ? "coinTable active" : "coinTable" }key={index} onClick={() => { onClickCoinInfo(value) }}>                       
                        <div className='container'>
                            <div className='title'>
                                <div className="coinImg"><img src={value.image}/></div>
                                {value.name}
                            </div>
                            <div className="balance">
                                <div className='price'><span>$&nbsp;</span>{comma(value.price)} </div>
                                <div className="rate"><img src={arrowImage} style={{width: 10, marginRight: 6}}/>{value.change}%</div>
                            </div>                     
                        </div>
                        {graphOpen == value.coinType && value.trendY != null && (
                            <div className="coin-graph">
                                <ResponsiveContainer width="100%" aspect={3/1}>
                                    <LineChart width={400} height={200} data={sortedDate}>
                                        <CartesianGrid stroke="#fff2" strokeDasharray="2 2 2" />
                                        <XAxis
                                            dataKey={"logDate"}                                           
                                            tickFormatter={formatXAxis}
                                        />
                                        <YAxis
                                            width={80}
                                            tickFormatter={formatYAxis}
                                            domain={['auto', 'auto']} />
                                        <Tooltip
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey={"price"}
                                            stroke="#FAB614"
                                            activeDot={{ r: 3 }}
                                            dot={false}
                                            isAnimationActive={true}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        )}
                    </div>
                );
            })}
            </div>
        </div>       
    )
}


export default Currency;